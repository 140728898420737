body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    font-family: News Cycle, Arial Narrow Bold, sans-serif;
    background-color: #f8f9fa;
}

.login-container {
    height: 100vh;
    background-color: #f7f7f7;
}
.card {
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
}
.card-body {
    padding: 2rem;
}
h3 {
    font-weight: bold;
}
.alert {
    margin-bottom: 1rem;
}
.form-control {
    border-radius: 0.25rem;
}
.loading-container {
    text-align: center;
}
.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 10px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-text {
    font-size: 16px;
    color: #343a40;
}
.version-string{
    bottom: 0;
    right: 0;
    font-size: 12px;
    color: gray;
    font-family: News Cycle, Arial Narrow Bold, sans-serif;
    position: fixed;
}

.logo-login-top{
    width: 100%;
}
.logo-login-bottom{
    margin-top: 20px;
    width: 70%;
}