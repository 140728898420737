.sidebar {
    box-shadow: 5px 0 10px #777;
    background-color: #2c3e50!important;
}
.sidebar h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
}
.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}
.sidebar li {
    margin-bottom: 6px;
}
.sidebar a {
    color: white;
    text-decoration: none;
    font-size: 0.95em;
    display: block;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background 0.3s ease, padding 0.3s ease;
}
.sidebar a:hover {
    background-color: #34495e;
    padding-left: 20px;
}
.sidebar .logout-btn {
    margin-top: 30px;
    background-color: #e74c3c;
    color: white;
    border: none;
    font-size: 0.9em;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s ease;
}
.sidebar .logout-btn:hover {
    background-color: #c0392b;
}


.accordion .accordion-item {
    border: none;
}
.accordion .accordion-header {
    border: none;
    background-color: transparent;
}
.accordion .accordion-body {
    border-top: none;
    box-shadow: none;
    padding: 1rem;
}
.accordion .accordion-button:not(.collapsed) {
    background-color: #f8f9fa;
    color: #333;
    box-shadow: none;
}
.accordion .accordion-button {
    background-color: transparent;
    color: #007bff;
    border: none;
}
.accordion .accordion-button:hover {
    background-color: #e9ecef;
}

.card-box{
    border-radius: 8px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 10px;
    margin-top: 14px;
}

.card-box-alternative{
    border-radius: 4px;
    border: solid 1px #D1E4F1;
    background-color: white;
    padding: 4px;
    margin:4px;
}

.profile {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 4px 0;
    background-color: white;
}
.profile-avatar {
    width: 50px;
    height: 50px;
    background-color: gray;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
}
.profile-avatar-reportado {
    background-color: #4C8BF5;
}
.profile-avatar-responsable {
    background-color: #4CAF50;
}
.profile-avatar-asignado {
    background-color: #E57373;
}
.profile-info {
    font-size: 12px;
}
.profile-info p {
    margin: 5px 0;
}
textarea{
    font-size: 14px!important;
}



.dates-container h2 {
    margin-bottom: 15px;
    color: #2c3e50;
    text-align: center;
}
.date-item {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}
.date-item:last-child {
    border-bottom: none;
}
.date-label {
    font-weight: bold;
    color: #34495e;
    font-size: 12px;
}
.date-value {
    color: #2c3e50;
    font-size: 12px;
}


.status-value.in-progress {
    background-color: #27ae60;
    color: #fff;
}
.height-min-content{
    height: min-content;
}
.task-status-container {
    font-family: 'Arial', sans-serif;
    background-color: #fefefe;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 15px;
    margin: 20px auto;
    height: min-content;
}

.task-status-container h2 {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #2c3e50;
    text-align: center;
}

.task-status, .task-select, .task-hours {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 120px;
}

.task-status label, .task-select label, .task-hours label {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 5px;
    color: #34495e;
}

.task-hours{
    margin-top: 20px;
}

.status-value {
    display: inline-block;
    padding: 5px 10px;
    background-color: #ecf0f1;
    border-radius: 4px;
    color: #2c3e50;
    font-size: 0.9em;
}

select, input[type="number"] {
    width: 100%;
    padding: 8px;
    font-size: 0.9em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

select:focus, input[type="number"]:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}



.comment-box-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 14px auto;
    background-color: #ffffff;
    box-shadow: 0 3px 3px #eaeaea;
    border-radius: 8px;
    padding: 15px;
    font-family: 'Arial', sans-serif;
}
.comment-box {
    width: 100%;
    min-height: 120px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    box-sizing: border-box;
}
.comment-box:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}
.comment-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}
.action-btn {
    background-color: #f4f4f4;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 8px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-btn i {
    font-size: 1.2em;
}
.action-btn:hover {
    background-color: #e8e8e8;
    transform: scale(1.1);
}
.send-btn {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 5px;
}
.send-btn:hover {
    background-color: #2980b9;
    transform: scale(1.05);
}




.comment-list-container {
    margin: 20px auto;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.comment {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
}

.comment:last-child {
    border-bottom: none;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-avatar {
    background-color: #3498db;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2em;
}

.user-details .user-name {
    font-size: 14px;
    font-weight: 600;
}

.comment-date {
    font-size: 12px;
    color: #888;
}

.user-comment {
    font-size: 14px;
    line-height: 1.6;
}

.attachments {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.image-attachment img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

.link-attachment a {
    font-size: 12px;
    color: #3498db;
    text-decoration: none;
}

.pdf-attachment {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}

.pdf-attachment i {
    color: #e74c3c;
}

.pdf-attachment a {
    color: #e74c3c;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Fondo con opacidad */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

td, th, li, div {
    font-family: "News Cycle","Arial Narrow Bold",sans-serif!important;
}

.modal-content-lg{
    max-width: 800px;
}

.profile-avatar-table{
    width: 35px;
    height:35px;
    font-size: 14px;
}
.profile-avatar-table:hover{
    cursor: pointer;
    opacity: 0.8;
}
.activity-item:hover > td{
    cursor: pointer;
    background-color: rgba(173, 216, 230, 0.1);
}
.color-palette-circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 1px;
    cursor: pointer;
}
.color-palette-td{
    width: 30px;
    height: 30px;
}
.logo-sidebar{
    margin-bottom: 30px;
}
.logo-sidebar > img{
    width: 150px;
}

/* custom select */

.custom-select {
    position: relative;
    width: 100%;
}

.select-header {
    padding: 6px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}

.select-options {
    position: absolute;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
    box-shadow: 1px 1px 4px gray;
}

.select-options li {
    padding: 6px;
    cursor: pointer;
    text-align: center;
}

.select-options li:hover {
    opacity: 0.8;
}



/* Estilos generales */
.user-list-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
}

.search-container {
    margin-bottom: 20px;
}
.search-container ul {
    list-style: none;
    padding: 1px;
}

.search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 5px;
}

.search-input:focus {
    outline: none;
    border-color: #007bff;
}

.user-list {
    list-style-type: none;
    padding: 0;
}

.user-list-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 1px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-list-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.user-item-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.user-initials {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
    margin-right: 15px;
    text-transform: uppercase;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    background-color: #e9f0f9;
}

.user-name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.suggestion-item {
    padding: 5px 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f1f1f1;
}

.card-float{
    position: relative;
    top: -18px;
    z-index: 3;
}
.card-float > .user-list-container {
    position: absolute;
}

.round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    font-size: 24px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.round-close-button{
    width: 30px;
    height: 30px;
    background-color: red;
    z-index: 10;
    margin-top:20px;
    margin-right: -10px;
}

.round-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
}

.round-button i {
    margin: 0 5px;
}

.button-assign-users-container{
    position: relative;
}

.button-assign-users-container .round-button{
    position: absolute;
    right: 0;
    top:-30px;
}

.user-assigned-item{
    position: relative;
}
.user-assigned-item:hover{
    cursor: pointer;
    background-color: rgba(173, 216, 230, 0.1);
}
.user-assigned-item .button-assign-users-container{
    display: none;
    position: absolute;
}
.user-assigned-item:hover .button-assign-users-container{
    display: flex;
}
.user-assigned-item .round-close-button{
    left: 230px;
    top: -36px;
}
.option-item-block{
    display: inline-block;
    list-style: none;
    margin: 3px;
    padding: 3px;
    border-radius: 10px;
    background-color: #F0F8FF;
    border:solid 1px #D6EAF8;
}
.btn-hover:hover{
    cursor: pointer;
}
.form-check-label{
    margin: 10px 0;
    font-size: 14px;
    font-weight: normal;
}
.expired-row {
    background-color: rgba(255, 0, 0, 0.2)!important;
    color: black;
}
.cursor-crosshair {
    cursor: crosshair !important;
}

.cursor-default {
    cursor: grab !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.expand-btn {
    background: transparent;
    border: none;
    color: black;
    font-size: 1.5rem;
    cursor: pointer;
}

.subtasks-container {
    background: #f8f9fa;
    padding: 10px;
    border-left: 4px solid #007bff;
    margin-top: 5px;
    border-radius: 5px;
}
.subtask-item {
    padding: 5px 10px;
}
.indented-row {
    border-left: solid white 5px;
    border-right: solid white 5px;
}
.btn-xsmall {
    width: 26px;
    height: 26px;
    margin: 0;
    padding: 0;
}

.comment-box-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
}

.comment-box {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}

.attachment-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: #ffffff;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    max-height: 150px;
    overflow-y: auto;
}

.attachment-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background: #f8f9fa;
    border-radius: 5px;
    font-size: 14px;
    padding-left: 10px;
    border: 1px solid #dee2e6;
}

.attachment-item i {
    margin-right: 10px;
}

.remove-attachment {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-attachment i {
    font-size: 18px;
}

.comment-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.action-btn, .send-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-btn i, .send-btn i {
    margin-right: 5px;
}

.action-btn:hover, .send-btn:hover {
    background: #0056b3;
}

.remove-attachment .bi{
    margin-right: 0;
}

.image-preview > img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}